import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { Alert, Button, Typography } from 'amphitheatre';

import Layout from 'src/Layout';

import Container from 'src/Components/ContentContainer';
import ToTop from 'src/Components/ToTop';

import * as Base from 'src/page-styles/base.styled';

import Step1Image from 'src/assets/images/tutorials/step4/pic1.png';
import Step2Image from 'src/assets/images/tutorials/step4/pic2.png';
import Step3Image from 'src/assets/images/tutorials/step4/pic3.png';
import Step4Image from 'src/assets/images/tutorials/step4/pic4.png';
import Step5Image from 'src/assets/images/tutorials/step4/pic5.png';
import Step6Image from 'src/assets/images/tutorials/step4/pic6.png';

const { Header, Paragraph } = Typography;

const BtnGroup = styled.div`
  display: flex;
  justify-content: space-between;
`;

function Step1(): JSX.Element {
  return (
    <Layout>
      <ToTop />
      <Container.Dark>
        <Base.MenuGap />
        <Base.Heading>Tutorial 4: Save a scene</Base.Heading>
        <Base.Text>
          <Header level={3}>Step 1</Header>
          <Base.Image src={Step1Image} />
          <Paragraph>
            An overpass is when a satellite passes over a ground station that
            can communicate with that satellite.
          </Paragraph>
          <Paragraph>
            There is a button in the top right corner of the screen, click it to
            bring down overpass data.
          </Paragraph>
          <Base.Gap />
          <Header level={3}>Step 2</Header>
          <Base.Image src={Step2Image} />
          <Paragraph>
            The left column lists the ground stations in your scene, the right
            shows the satellites which exist in your scene. The middle column is
            marked out to denote time, with overpass data shown as coloured
            bars.
          </Paragraph>
          <Base.Gap />
          <Header level={3}>Step 3</Header>
          <Base.Image src={Step3Image} />
          <Paragraph>
            For more detailed overpass information, click the ‘Show Overpass
            Info’ button on the bottom left of the overpass panel.
          </Paragraph>
          <Base.Gap />
          <Header level={3}>Step 4</Header>
          <Base.Image src={Step4Image} />
          <Paragraph>
            A dialog box will appear, you will be required to set the date,
            which is accessible through a button towards the top. Set the date
            to the required UTC with the current UTC shown on the right of your
            screen on the bottom of the overpass data tab. Set your duration to
            one day for your overpass data set, and ensure adjust the ‘Julian
            Date’ toggle to your data to get the right calculations, then click
            ‘Calculate’.
          </Paragraph>
          <Base.Gap />
          <Header level={3}>Step 5</Header>
          <Base.Image src={Step5Image} />
          <Paragraph>
            Overpass times are visible under the AOS (Acquisition Of
            Signal/Satellite) and LOS (Loss of Signal/Satellite) labels, with
            the overall duration further on in the chart shown as HH:MM:SS. The
            duration of your overpass can be seen in the right most column, it
            is a calculation of your LOS time minus your AOS time for that
            particular overpass.
          </Paragraph>
          <Base.Gap />
          <Header level={3}>Step 6</Header>
          <Base.Image src={Step6Image} />
          <div style={{ margin: '1rem 0' }}>
            <Alert info>
              You will find your saved overpass data in your local drive,
              <i>C:\Users\(account)</i>
            </Alert>
          </div>
          <Paragraph>
            In order to export data, there is a button on the top right corner
            of the overpass data dialog box. Click this to bring up the save
            function, name your file and click save.
          </Paragraph>
          <BtnGroup>
            <Link to="/tutorials/tutorial3" style={{ textDecoration: 'none' }}>
              <Button ghost>Prev</Button>
            </Link>
            <Link to="/tutorials/tutorial5" style={{ textDecoration: 'none' }}>
              <Button>Next</Button>
            </Link>
          </BtnGroup>
        </Base.Text>
      </Container.Dark>
    </Layout>
  );
}

export default Step1;
